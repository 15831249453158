
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import GeneralVehicleSelect from "@/components/scope/general-vehicle-select.vue";
import { emptyToLine } from "@/utils/common";
import t from "@common/src/i18n/t";

const guidance = namespace("guidance");
const base = namespace("base");
@Component({
  components: { GeneralVehicleSelect }
})
export default class GuidanceIndex extends Vue {
  @guidance.Action getVehicleInfoByModeCode;
  @base.Action getVehicleInfoByVin;
  emptyToLine = emptyToLine;
  t = t;
  currentTabType: string = "vehicle";
  filterData: any = {
    vehicleModelCodes: "",
    vinCode: ""
  };
  vehicleInfo: any = {};
  vehicleListByVin: any = [];
  showSelectVehicleMode: boolean = false;
  get tabBarData() {
    return [
      { tabName: t("v210831.vehicle-type-query"), type: "vehicle" },
      { tabName: t("v210831.vin-code-query"), type: "vin" }
    ];
  }
  curCategoryType: string = "1";
  created() {
    this.init();
  }
  defaultVehicleList: any = [];
  init() {
    const modeCode = this.$route.query.modeCode;
    if (modeCode && modeCode !== "null" && modeCode !== "undefined") {
      this.filterData.vehicleModelCodes = modeCode;
      this.getVehicleInfoByModeCode({
        levelId: modeCode
      }).then(data => {
        this.pageStatus = "query";
        this.vehicleInfo = this.nullToEmptyObj(data.data);
        data.data.modelCode = modeCode;
        this.defaultVehicleList = this.vehicleDataFormat([data.data]);
      });
    } else {
      setTimeout(() => {
        this.$nextTick(() => {
          (this.$refs.vehicleSelector as any).expandSelector();
        });
      }, 300);
    }
  }
  vehicleDataFormat(data) {
    return data.map(item => ({
      lastValue: item.modelCode,
      name: item.brandName + " / " + item.seriesName + " / " + item.modelDesc,
      valueGroup: [item.brandName, item.seriesName, item.modelCode]
    }));
  }
  searchVehicleByVinCode() {
    if (!this.filterData.vinCode) return;
    this.getVehicleInfoByVin({
      vinCode: this.filterData.vinCode
    }).then(
      data => {
        if (data.data.length > 1) {
          this.showSelectVehicleMode = true;
          this.vehicleListByVin = data.data;
        } else if (data.data.length === 1) {
          this.handleSelectVehiclebyVin(data.data[0]);
        }
      },
      data => {
        this.$message.error(data.message);
        // (this.$refs.vehicleSelect as any).reset();
      }
    );
  }
  handleSelectVehiclebyVin(vehicleData) {
    this.getVehicleInfoByModeCode({
      levelId: vehicleData.modelCode
    }).then(data => {
      this.pageStatus = "query";
      this.vehicleInfo = this.nullToEmptyObj(data.data);
      this.showSelectVehicleMode = false;
    });
  }
  get TiresInfo() {
    return [
      {
        label: t("v210831.front-tire-specification"),
        value: emptyToLine(this.vehicleInfo.vehicleMatchingTire.frontTireType)
      },
      {
        label: t("v210831.rear-tire-specification"),
        value: emptyToLine(this.vehicleInfo.vehicleMatchingTire.backTireType)
      }
    ];
  }
  get wheelHub() {
    return [
      {
        label: t("v210831.front-hub-specification"),
        value: emptyToLine(this.vehicleInfo.vehicleMatchingTire.frontWheelType)
      },
      {
        label: t("v210831.rear-hub-specification"),
        value: emptyToLine(this.vehicleInfo.vehicleMatchingTire.backWheelType)
      }
    ];
  }
  get spareTire() {
    return [
      {
        label: t("v210831.spare-wheel-specification"),
        value: emptyToLine(this.vehicleInfo.vehicleMatchingTire.atfOverhaulQuantity)
      }
    ];
  }
  get engineOil() {
    return [
      {
        label: t("v210831.base-oil"),
        value: emptyToLine(this.vehicleInfo.vehicleMatchingOil.eoBoType)
      },
      {
        label: t("v210831.level"),
        value: emptyToLine(this.vehicleInfo.vehicleMatchingOil.eoLevel)
      },
      {
        label: t("parts.specifications"),
        value: emptyToLine(this.vehicleInfo.vehicleMatchingOil.eoSpecs)
      },
      {
        label: t("v210831.maintenance-filling"),
        value: emptyToLine(this.vehicleInfo.vehicleMatchingOil.eoMaintainQuantity)
      }
    ];
  }
  get variableSpeedBoxOil() {
    // 变速箱油
    return [
      {
        label: t("parts.specifications"),
        value: emptyToLine(this.vehicleInfo.vehicleMatchingOil.tfSpecs)
      },
      {
        label: t("v210831.overhaul-filling"),
        value: emptyToLine(this.vehicleInfo.vehicleMatchingOil.tfOverhaulQuantity)
      },
      {
        label: t("v210831.maintenance-filling"),
        value: emptyToLine(this.vehicleInfo.vehicleMatchingOil.tfMaintainQuantity)
      }
    ];
  }

  get brakeFluid() {
    return [
      {
        label: t("parts.specifications"),
        value: emptyToLine(this.vehicleInfo.vehicleMatchingOil.boSpecs)
      },
      {
        label: t("v210831.filling-quantity"),
        value: emptyToLine(this.vehicleInfo.vehicleMatchingOil.boQuantity)
      }
    ];
  }
  get powerSteering() {
    // 助力转向油
    return [
      {
        label: t("v210831.filling-quantity"),
        value: emptyToLine(this.vehicleInfo.vehicleMatchingOil.sboQuantity)
      }
    ];
  }
  get airConditioningRefrigerant() {
    // 空调制冷剂
    return [
      {
        label: t("parts.specifications"),
        value: emptyToLine(this.vehicleInfo.vehicleMatchingOil.acrSpecs)
      },
      {
        label: t("v210831.filling-quantity"),
        value: emptyToLine(this.vehicleInfo.vehicleMatchingOil.acrQuantity)
      }
    ];
  }
  get coolant() {
    // 冷却液
    return [
      {
        label: t("parts.specifications"),
        value: emptyToLine(this.vehicleInfo.vehicleMatchingOil.coolantSpecs)
      },
      {
        label: t("v210831.filling-quantity"),
        value: emptyToLine(this.vehicleInfo.vehicleMatchingOil.coolantQuantity)
      }
    ];
  }
  get airFilter() {
    // 空气滤清器
    return [
      {
        label: t("v210831.oe-no"),
        value: emptyToLine(this.vehicleInfo.vehicleMatchingFilterAir.oeNumber)
      },
      {
        label: t("parts.specifications"),
        value: emptyToLine(this.vehicleInfo.vehicleMatchingFilterAir.specs)
      },
      {
        label: t("v210831.consumption"),
        value: emptyToLine(this.vehicleInfo.vehicleMatchingFilterAir.dosage)
      },
      {
        label: t("v210831.installation-position"),
        value: emptyToLine(this.vehicleInfo.vehicleMatchingFilterAir.installLocation)
      },
      {
        label: t("v210831.oe-replacement-part"),
        value: emptyToLine(this.vehicleInfo.vehicleMatchingFilterAir.replacePartsNumber)
      },
      {
        label: t("v210831.replace-notes"),
        value: emptyToLine(this.vehicleInfo.vehicleMatchingFilterAir.replaceRemark)
      },
      {
        label: t("v210831.application-notes"),
        value: emptyToLine(this.vehicleInfo.vehicleMatchingFilterAir.installRemark)
      }
    ];
  }
  get oilFilter() {
    // 机油滤清器
    return [
      {
        label: t("v210831.oe-no"),
        value: emptyToLine(this.vehicleInfo.vehicleMatchingFilterEo.oeNumber)
      },
      {
        label: t("parts.specifications"),
        value: emptyToLine(this.vehicleInfo.vehicleMatchingFilterEo.specs)
      },
      {
        label: t("v210831.consumption"),
        value: emptyToLine(this.vehicleInfo.vehicleMatchingFilterEo.dosage)
      },
      {
        label: t("v210831.installation-position"),
        value: emptyToLine(this.vehicleInfo.vehicleMatchingFilterEo.installLocation)
      },
      {
        label: t("v210831.oe-replacement-part"),
        value: emptyToLine(this.vehicleInfo.vehicleMatchingFilterEo.replacePartsNumber)
      },
      {
        label: t("v210831.replace-notes"),
        value: emptyToLine(this.vehicleInfo.vehicleMatchingFilterEo.replaceRemark)
      },
      {
        label: t("v210831.application-notes"),
        value: emptyToLine(this.vehicleInfo.vehicleMatchingFilterEo.installRemark)
      }
    ];
  }
  get fuelFilter() {
    // 燃油滤清器
    return [
      {
        label: t("v210831.oe-no"),
        value: emptyToLine(this.vehicleInfo.vehicleMatchingFilterFo.oeNumber)
      },
      {
        label: t("parts.specifications"),
        value: emptyToLine(this.vehicleInfo.vehicleMatchingFilterFo.specs)
      },
      {
        label: t("v210831.consumption"),
        value: emptyToLine(this.vehicleInfo.vehicleMatchingFilterFo.dosage)
      },
      {
        label: t("v210831.installation-position"),
        value: emptyToLine(this.vehicleInfo.vehicleMatchingFilterFo.installLocation)
      },
      {
        label: t("v210831.oe-replacement-part"),
        value: emptyToLine(this.vehicleInfo.vehicleMatchingFilterFo.replacePartsNumber)
      },
      {
        label: t("v210831.replace-notes"),
        value: emptyToLine(this.vehicleInfo.vehicleMatchingFilterFo.replaceRemark)
      },
      {
        label: t("v210831.application-notes"),
        value: emptyToLine(this.vehicleInfo.vehicleMatchingFilterFo.installRemark)
      }
    ];
  }
  get airConditioningFilter() {
    // 空调滤清器
    return [
      {
        label: t("v210831.oe-no"),
        value: emptyToLine(this.vehicleInfo.vehicleMatchingFilterAc.oeNumber)
      },
      {
        label: t("parts.specifications"),
        value: emptyToLine(this.vehicleInfo.vehicleMatchingFilterAc.specs)
      },
      {
        label: t("v210831.consumption"),
        value: emptyToLine(this.vehicleInfo.vehicleMatchingFilterAc.dosage)
      },
      {
        label: t("v210831.installation-position"),
        value: emptyToLine(this.vehicleInfo.vehicleMatchingFilterAc.installLocation)
      },
      {
        label: t("v210831.oe-replacement-part"),
        value: emptyToLine(this.vehicleInfo.vehicleMatchingFilterAc.replacePartsNumber)
      },
      {
        label: t("v210831.replace-notes"),
        value: emptyToLine(this.vehicleInfo.vehicleMatchingFilterAc.replaceRemark)
      },
      {
        label: t("v210831.application-notes"),
        value: emptyToLine(this.vehicleInfo.vehicleMatchingFilterAc.installRemark)
      }
    ];
  }
  get Accumulator() {
    // 蓄电池
    return [
      {
        label: t("v210831.product-number"),
        value: emptyToLine(this.vehicleInfo.vehicleMatchingBattery.productModel)
      },
      {
        label: t("v210831.product-series"),
        value: emptyToLine(this.vehicleInfo.vehicleMatchingBattery.productSeries)
      }
    ];
  }
  get categoryTabData() {
    return [
      { tabName: t("v210831.tyre"), type: "1", iconName: "icon-guidance-tire" },
      { tabName: t("v210831.oil"), type: "2", iconName: "icon-guidance-oil" },
      { tabName: t("v210831.four-filter"), type: "3", iconName: "icon-guidance-filter" },
      { tabName: t("v210831.battery"), type: "4", iconName: "icon-guidance-battery" }
    ];
  }
  pageStatus: string = "init"; // init  query
  handleSwitchStatus(tab) {
    this.currentTabType = tab.type;
  }
  handleSwichCategory(data) {
    this.curCategoryType = data.type;
  }
  handelInitType() {
    // this.pageStatus = "init";
    if (this.currentTabType === "vehicle") {
      // this.filterData.vehicleModelCodes = "";
    } else {
      // this.filterData.vinCode = "";
    }

    this.currentTabType = this.currentTabType === "vehicle" ? "vin" : "vehicle";
    if (this.currentTabType === "vehicle") {
      if (this.filterData.vehicleModelCodes) {
        this.getVehicleInfoByModeCode({
          levelId: this.filterData.vehicleModelCodes
        }).then(data => {
          this.pageStatus = "query";
          this.vehicleInfo = this.nullToEmptyObj(data.data);
        });
      }
    } else {
      if (this.filterData.vinCode) {
        this.searchVehicleByVinCode();
      }
    }
  }
  nullToEmptyObj(data) {
    data.vehicleMatchingBattery = data.vehicleMatchingBattery || {};
    data.vehicleMatchingFilterAc = data.vehicleMatchingFilterAc || {};
    data.vehicleMatchingFilterAir = data.vehicleMatchingFilterAir || {};
    data.vehicleMatchingFilterEo = data.vehicleMatchingFilterEo || {};
    data.vehicleMatchingFilterFo = data.vehicleMatchingFilterFo || {};
    data.vehicleMatchingOil = data.vehicleMatchingOil || {};
    data.vehicleMatchingTire = data.vehicleMatchingTire || {};
    return data;
  }
  handleQueryConstructionInfo(operate) {
    if (this.currentTabType === "vehicle") {
      if (!this.filterData.vehicleModelCodes) {
        return this.$message.error(t("test.select-car-model"));
      }
    } else {
      if (!this.filterData.vinCode) {
        return this.$message.error(t("v210831.please-enter-the-vin"));
      }
    }
    this.curCategoryType = "1";
    if (this.currentTabType === "vehicle") {
      this.getVehicleInfoByModeCode({
        levelId: this.filterData.vehicleModelCodes
      }).then(data => {
        this.pageStatus = "query";
        this.vehicleInfo = this.nullToEmptyObj(data.data);
      });
    } else {
      this.searchVehicleByVinCode();
    }
  }
}
